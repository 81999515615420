import { Col } from "react-bootstrap"

function ExperienceBody(props){
    return <Col>
            <p style={{textAlign: "left"}}>
                {props.text}
            </p>
        </Col>
}

export default ExperienceBody