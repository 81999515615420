import { Col } from "react-bootstrap"

function ExperienceTitle(props) {
    return <Col>
        <h2 style={{textAlign: "left"}}>{props.text}</h2>
    </Col>
    

}

export default ExperienceTitle